<template>
  <div class="white-body-view">
    <el-tree id="my-tree" ref="tree" class="tree-view structure-tree scroll-bar" :data="treeData" highlight-current
      :props="defaultProps" :default-expanded-keys="[1, 2]" :node-key="treeNodeKey" @node-click="handleNodeClick"
      :auto-expand-parent="false" :expand-on-click-node="false" :filter-node-method="filterNode"
      :default-checked-keys="[checkedID]" >
      <span slot-scope="{ node, data }" class="custom-tree-node">
        <span class="tooltip">
          <span class="add-f-s-14">{{ data.Name }}</span>
        </span>
      </span>
    </el-tree>
  </div>
</template>

<script>
export default {
  props: {
    // 列表数据
    treeData: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 树节点是否默认展开
    treeExpandAll: {
      type: Boolean,
      default: true
    },

    // 树节点唯一标识
    treeNodeKey: {
      type: String,
      default: ''
    },
    checkedID: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      defaultProps: {
        children: 'Children',
        label: 'Name'
      },
      selectItem: {}
    }
  },
  watch: {
    treeExpandAll(e) {
      this.treeExpandAll = e
    },
    checkedID() {
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(this.checkedID)
      })
    }
  },
  mounted() {
    this.$refs.tree.setCurrentKey(this.checkedID)
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    // 选中事件
    handleNodeClick(data) {
      this.$emit('handleNodeClick', data)
    },
  }
}
</script>
<style lang="scss" scoped>
.white-body-view {
  //width: 100%;
  //min-width: 320px;
}

.structure-tree {
  .el-scrollbar .el-scrollbar__wrap {
    //  overflow-y: auto;
  }

  #my-tree .el-tree>.el-tree-node {
    min-width: 100%;
    display: inline-block;
  }

  .el-tree-node__content {
    // margin-bottom: 10px;
  }

  .tooltip {
    margin-right: 5px;
    font-size: 13px;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    padding: 4px;
  }

  .operation-view {
    padding: 0px 5px;
    margin-left: 5px;
    color: #3473F4 !important;
    display: flex;
    align-items: center;
  }

  .operation-view i {
    color: inherit !important;
  }

  .small-operation-btn {
    margin: 0px 3px;
  }
}

.el-icon-plus {
  color: #c0c4cc !important;
}

.el-icon-edit {
  color: #c0c4cc !important;
}

.el-icon-delete {
  color: #c0c4cc !important;
}

.el-icon-plus:hover {
  color: #303133 !important;
}

.el-icon-edit:hover {
  color: #303133 !important;
}

.el-icon-delete:hover {
  color: #303133 !important;
}

::v-deep .el-tree {
  color: #333333 !important;
}
</style>